import React from 'react';
import { helpers, images, types } from 'helpers';
import { PeopleOutlineOutlined } from '@mui/icons-material';

interface Props {
    items: (types.Social & { price?: number })[];
    size?: 'default' | 'large';
    style?: 'float' | 'full';
    clickable?: boolean;
    showKount?: 'count' | 'price';

    onChange?: (socials: string[]) => void;
}
const CuratorsSocials = (props: Props) => {
    const { items, clickable, showKount, onChange } = props;

    const [selected, setSelected] = React.useState<string[]>([]);

    const size = props.size || 'default';
    const style = props.style || 'float';

    React.useEffect(() => {
        onChange && onChange(selected);
    }, [selected]);

    const icons = {
        [types.SocialPlatforms.fb]: images.SocialFacebook,
        [types.SocialPlatforms.th]: images.SocialThreads,
        [types.SocialPlatforms.tt]: images.SocialTiktok,
        [types.SocialPlatforms.twt]: images.SocialTwitter,
        [types.SocialPlatforms.blog]: images.SocialGlobe,
        [types.SocialPlatforms.ig_reel]: images.SocialInstagram,
        [types.SocialPlatforms.ig_carousel]: images.SocialInstagram,
    };

    return (
        <React.Fragment>
            <div id="CuratorsSocials">
                {items.map((item, i) => (
                    <div
                        key={`${i}_${item.platform}`}
                        className={`
                            rounded-full border-[1.5px] ${size === 'default' ? 'px-3 py-2 text-sm' : 'px-3 py-3 text-xl font-medium'} text-gray-500 flex gap-2 items-center ${style === 'float' ? 'float-left' : ''} mr-2 mb-2 transi 
                            ${selected.includes(item.platform) ? 'border-[#fc003f] text-[#fc003f] bg-[#FFF0F3]' : ''} ${clickable ? 'cursor-pointer hover:border-[#fc003f] hover:text-[#fc003f] hover:bg-[#FFF0F3]' : ''}
                        `}
                        onClick={() => clickable && (selected.includes(item.platform) ? setSelected((ss) => (ss.filter(s => s !== item.platform))) : setSelected((ss) => ([...ss, item.platform])))}
                    >
                        {icons[item.platform] && (<img src={icons[item.platform]} className={`${size === 'default' ? 'h-[15px]' : 'h-[20px] mr-1'} rounded-fulls`} />)}
                        {item.handle ? `${item.platform !== 'blog' ? '@' : ''}${item.handle}` : types.SocialPlatformsLabels[item.platform]}
                        {showKount && showKount === 'count' && +item.count > 0 ? <span className="font-bold text-black"><PeopleOutlineOutlined /> {helpers.format.social(item.count)}</span> : ''}
                        {showKount && showKount === 'price' && item.price ? <span className="font-bold text-black">{helpers.format.currency(item.price)}</span> : ''}
                    </div>
                ))}
                <div className="clear-both" />
            </div>
        </React.Fragment>
    );
};

export default CuratorsSocials;