import React from 'react';
import { Spin } from 'antd';
import { LoadingMin } from './Loading';
import { images } from 'helpers';

interface Props {
    data: any;
    empty?: JSX.Element;
    loading: boolean;
    heading: string[];
    loadingHeight?: number;

    onClick?: (row: any) => void;
}
const Table = ({ data, empty, loading, heading, loadingHeight, onClick }: Props) => {

    const thClass = 'border-gray-200 px-5 py-4 border-ts text-left font-semibold text-gray-600s text-sm bg-[#F9FAFB] bg-opacity-80s text-[#525866]';
    const tdClass = 'px-5 py-4 text-[#525866] border-t text-sm transi';

    return (
        <React.Fragment>
            {loading ? (
                <div className="bg-gray-50s rounded-bl-xl rounded-br-xl borders">
                    <Spin spinning={true} indicator={<div className="rounded-xl flex justify-centers items-center"><LoadingMin size={35} /></div>}>
                        <div style={{ height: loadingHeight || 525 }} />
                    </Spin>
                </div>
            ) : (
                <div id="Table" className="bg-whites rounded-xl border">
                    <table className="w-full p-4s borders">
                        {(data || []).length > 0 && (
                            <thead className="">
                                <tr>
                                    {heading.map((th) => (
                                        <th key={th} className={`${thClass}`}>{th}</th>
                                    ))}
                                </tr>
                            </thead>
                        )}
                        <tbody className="">
                            {(data || []).length === 0 && (
                                <tr>
                                    <td colSpan={15} align="center" className={`${tdClass} border-t-0 bg-[#F9FAFB]`} style={{ height: 371 }}>
                                        {empty || (
                                            <>
                                                <img src={images.EmptyTable} alt="EmptyTable" className="h-[51px]" />
                                                <div className="text-gray-500s font-light mt-3">No data to display...</div>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )}
                            {(data || []).length > 0 && (data || []).map((row: any, i: number) => (
                                <tr key={`td-${i}`} className={`hover:bg-[#F9FAFB] transi ${onClick ? 'cursor-pointer' : ''}`}>
                                    {Object.keys(row).map(rowKey => {
                                        const clickable = typeof row[rowKey] !== 'object' || (typeof row[rowKey] === 'object' && (typeof row[rowKey].clickable === 'undefined' || row[rowKey].clickable));
                                        return (
                                            rowKey !== 'id' && (
                                                <td
                                                    key={rowKey}
                                                    className={`${tdClass}`}
                                                    onClick={() => onClick && clickable && onClick(row)}
                                                >
                                                    {typeof row[rowKey] === 'object' ? (row[rowKey].value || row[rowKey]) : row[rowKey]}
                                                </td>
                                            )
                                        )
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </React.Fragment>
    );
};

export default Table;