import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
import { ConfigProvider } from 'antd';

import { StoreProvider } from './_Store';

import './index.less';
import './assets/scss/_scss.scss';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#fc003f',
    },
    secondary: {
      main: '#045e60',
    },
  },
});

const antdTheme = {
  token: {
    colorPrimary: '#fc003f',
    colorSecondary: '#045e60',
    borderRadius: 12,
    fontFamily: '"Raleway", sans-serif',
  },
};


// Sentry.init({
//   dsn: config.sentry_dns,
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ['https://dash.ogateway.io/', 'https://internal-dash.ogateway.io/'],
//     }),
//     new Sentry.Replay(),
//   ],
//   environment: config.env,
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 1.0,
//   replaysOnErrorSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <ConfigProvider theme={antdTheme}>
        <StoreProvider>
          <App />
        </StoreProvider>
      </ConfigProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
