import { notification } from 'antd';
import moment from 'moment';
import { parsePhoneNumber } from 'awesome-phonenumber';
import * as rdc from 'react-device-detect';
import { types } from 'helpers';


export const config = {
    env: 'local',
    dbpref: 'urpr:',
};

export const app = {
    version: require('../../package.json').version,
}

export const isJson = (str: any) => {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}


// ::: storage
export const setStorage = (key: string, value: string) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, value);
    }
}
export const getStorage = (key: string) => {
    const value = localStorage.getItem(config.dbpref + key);
    return value || '';
}
export const setStorageJson = (key: string, value: any) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key: string) => {
    if (key) {
        const value = localStorage.getItem(config.dbpref + key) || '';
        return isJson(value) ? JSON.parse(value) : '';
    }
}
export const delStorage = (key: string) => {
    if (key) {
        localStorage.removeItem(config.dbpref + key);
    }
}

export const redirect = (to: any) => {
    window.location = to;
}

export const openNewTab = (to: any) => {
    window.open(to, '_blank');
}

export const generateOptions = (length: number, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const format = {
    date: (date: string) => isToday(date) ? `Today @ ${moment(date).format('H:mm A')}` : moment(date).format('MMM DD, YYYY HH:mm:ss'),
    number: (number: number, decimal = 2) => {
        number = number || 0;
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: decimal === 0 ? 0 : 2, maximumFractionDigits: decimal }).format(number);
    },
    currency_: (amount: number | string) => {
        return `GHS ${format.number(+amount)}`;
    },
    currency: (amount: number | string) => {
        return `$${format.number(+amount)}`;
    },
    phoneNumber: (msisdn: string) => {
        if (msisdn) {
            const regionCode = msisdn.includes('+') ? undefined : 'GH';
            const parsed = parsePhoneNumber(msisdn, { regionCode });
            return parsed.valid ? parsed.number.international : (msisdn.length > 20 ? msisdn : msisdn.match(/.{1,3}/g)?.join(' '));
        }
        return '';
    },
    social: (number: number) => {
        number = number || 0;
        return new Intl.NumberFormat('en', { notation: 'compact' }).format(number);
    }
}


export const randNum = (length = 6) => {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const randNumRange = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

export const randCode = (length = 6) => {
    let result = '';
    const characters = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const imgError = (e: any) => {
    e.target.src = '/favicon.png';
}

export const objectToArray = (obj: any) => {
    let data = [];
    if (Array.isArray(obj)) {
        data = obj;
    } else {
        for (let i = 0; i < Object.keys(obj).length; i++) {
            data.push(obj[i]);
        }
    }
    return data;
}

export const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then((_) => {
        notification.success({ message: `Copied` });
    });
}

export const md5 = (str: any) => {
    return btoa(JSON.stringify(str));
}

export const isToday = (date: string) => !!(moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));

export const daysBetweenDates = (startDate: any, endDate: any, duration: 'days' | 'months' = 'days') => {
    const dates = [];
    const format = { days: 'YYYY-MM-DD', months: 'YYYY-MM' };
    while (moment(startDate) <= moment(endDate)) {
        dates.push(startDate);
        startDate = moment(startDate).add(1, duration).format(format[duration]);
    }
    return dates;
}

export const isLocal = window.location.host.includes('localhost');
export const isStaging = window.location.host.includes('stage-');

export const ucFirst = (str: string) => {
    return str ? str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '';
}

export const ucWords = (str: string) => {
    return str ? str.split(' ').map(str => { return ucFirst(str); }).join(' ') : '';
}

export const copyObject = (a: any) => JSON.parse(JSON.stringify(a));

export const isMobile = !!(rdc.isMobile && !rdc.isTablet);

export const hasPermission = (_: string) => {
    // const user: types.User = JSON.parse(getStorageJson('auth').user || '{}');
    // if (user.roleId && user.role) {
    //     return user.role.permissions.includes(role);
    // }
    return true;
}

export const requiredInput = [{ required: true, message: 'This field is required' }];

export const objectToCsv = function (data: any) {
    if (data && data.length > 0) {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(Object.keys(data[0]).map(val => {
            return `"${val}"`;
        }).join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const val = row[header];
                return `"${val}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    }
}

export const downloadData = (name: string, data: any) => {
    const csv = objectToCsv(data);
    if (csv) {
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${name} ${moment().toISOString()}.csv`);
        a.click();
    } else {
        notification.error({ message: `Unable to export data.`, description: 'Please refresh your browser and try again!' });
    }
}

export const cost = (socials: types.CuratorForm[]) => socials.map(c => c.prices).flat().reduce((a, b) => (b.price + a), 0);