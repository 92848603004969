import React from 'react';
import { Tag } from 'antd';
import { helpers, types } from 'helpers';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, SyncOutlined, DollarCircleOutlined } from '@ant-design/icons';


interface Props {
    status: string;
}
const Status = ({ status }: Props) => {

    const icons = {
        [types.CampaignStatus.PENDING]: <ExclamationCircleOutlined />,
        [types.CampaignStatus.APPROVED]: <DollarCircleOutlined />,
        [types.CampaignStatus.REJECTED]: <CloseCircleOutlined />,
        [types.CampaignStatus.COMPLETED]: <CheckCircleOutlined />,
        [types.CampaignStatus.PROCESSING]: <SyncOutlined />,
    };

    const colors = {
        [types.CampaignStatus.PENDING]: 'orange',
        [types.CampaignStatus.APPROVED]: 'orange',
        [types.CampaignStatus.REJECTED]: 'error',
        [types.CampaignStatus.COMPLETED]: 'success',
        [types.CampaignStatus.PROCESSING]: 'processing',
    };

    return (
        <React.Fragment>
            <div id="Status" className="">
                {colors[status as keyof typeof colors] && (
                    <Tag icon={icons[status as keyof typeof icons]} color={colors[status as keyof typeof colors]} className="rounded-full">{helpers.ucWords(status)}</Tag>
                )}
            </div>
        </React.Fragment>
    );
}

export default Status;