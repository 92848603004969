
export interface AxiusResponse {
    data: any;
    status: number;
    message: string;
}

export interface ApiTrigger {
    data: any;
    error: any;
    message: string;
}

export interface StoreInterface {
    _store: {
        _auth: {
            user: User;
            token: string;
        },
        _utils: {
            limit: number;
            loading: {
                primary: string;
                spinning: boolean;
                secondary: string;
            };
            meCache: string;
        },
        _data: {

        }
    },
    _setAuth: (value: { user: User, token: string }) => void;
    _setUser: (value: User) => void;
    _setToken: (value: string) => void;
    _setData: (payload: { key: 'business', value: any }) => void;
    _setUtils: (payload: { key: 'loading' | 'limit' | 'meCache', value: any }) => void;
}

export interface User {
    id: string;
    name: string;
    email: string;
    password: string;
    created_at: string;
    updated_at: string;
    type: UserTypes;
    country: string;
    role_id?: string;
    role?: Role;
    category: Categories;
    avatar: string;
    avatar_link: string;
    wallet: {
        amount: number;
    };
}

export enum UserTypes {
    ADMIN = 'ADMIN',
    LABEL = 'LABEL',
    ARTIST = 'ARTIST',
    PUBLICIST = 'PUBLICIST',
}

export enum UserTypesLabels {
    ADMIN = 'Admin',
    LABEL = 'Record Label',
    ARTIST = 'Independant Artist',
    PUBLICIST = 'Publicist',
}

export interface Curator {
    id: string;
    name: string;
    logo: string;
    logo_link: string;
    description: string;
    prices: Price[];
    socials: Social[];
    categories: Categories[];
    created_at: string;
    updated_at: string;
}

export enum Categories {
    GOLD = 'GOLD',
    BRONZE = 'BRONZE',
    SILVER = 'SILVER',
    DIAMOND = 'DIAMOND',
}

export interface CuratorForm {
    id: string;
    prices: Price[];
}

export interface Role {
    id: string;
    name: string;
    permissions: string[];
    created_at: string;
    updated_at: string;
}

export interface Campaign {
    id: string;
    fee: number;
    name: string;
    links: {
        bio: string;
        press: string;
        assets: string;
        profile: string;
        instagram: string;
    };
    price: number;
    status: CampaignStatus;
    results: any;
    rejections: any;
    release_date: string;
    publish_date: string;
    user_id: string;
    user: User;
    created_at: string;
    updated_at: string;
    curators: Curator[];
    payment_link: string;
    artist_name: string;
    caption: string;
}

export enum CampaignStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    PROCESSING = 'PROCESSING',
    REJECTED = 'REJECTED',
    COMPLETED = 'COMPLETED',
    DELETED = 'DELETED',
}

export interface CampaignsCurators {
    id: string;
    prices: {
        price: number;
        platform: SocialPlatforms,
    }[];
    campaign_id: string;
    campaign: Campaign;
    created_at: string;
    updated_at: string;
}

export interface File {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}

export interface Filter {
    key: string;
    type: 'text' | 'number' | 'select' | 'date_range';
    value?: string;
    label?: string;
    options?: string[] | { label: string; value: string; }[];
    placeholder?: string;
}

export interface DataMeta {
    limit: number;
    next?: string;
    page: number;
    previous?: string;
    total: number;
}

export enum Folders {
    users = 'users',
    curators = 'curators',
    campaigns = 'campaigns',
}

export interface Social {
    count: number;
    handle: string;
    platform: SocialPlatforms;
}

export interface Price {
    price: number;
    platform: SocialPlatforms;
}

export enum SocialPlatforms {
    fb = 'fb',
    tt = 'tt',
    th = 'th',
    twt = 'twt',
    blog = 'blog',
    ig_reel = 'ig_reel',
    ig_carousel = 'ig_carousel',
}

export enum SocialPlatformsLabels {
    fb = 'FaceBook',
    tt = 'TikTok',
    th = 'Threads',
    twt = 'Twitter (X)',
    blog = 'Publish Article',
    ig_reel = 'IG Reel',
    ig_carousel = 'IG Carousel',
}

export const ServiceFee = {
    [UserTypes.ADMIN]: 0,
    [UserTypes.LABEL]: 15,
    [UserTypes.ARTIST]: 5,
    [UserTypes.PUBLICIST]: 10,
};