import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { axius, helpers, images, types } from 'helpers';
import _Store from '_Store';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { Avatar, Drawer } from 'antd';

const WalletForm = React.lazy(() => import('../pages/Wallet/WalletForm'));

const Leyout = ({ children }: { children: any }) => {

    const location = useLocation();

    const { _store: { _auth: { token, user }, _utils }, _setUser } = React.useContext(_Store);

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth') && !['/', '/terms-conditions', '/privacy-policy'].includes(location.pathname));

    const [drawer, setDrawer] = React.useState(false);

    const getMyDetails = () => {
        axius.get(`users/me`, { cache: _utils.meCache }).then(res => {
            res.status === 200 && _setUser(res.data);
        });
    }

    React.useEffect(() => {
        if (token) {
            getMyDetails();
        }
        // eslint-disable-next-line
    }, [token, _utils.meCache]);

    const items = [
        { icon: images.NavCurators, icon2: images.NavCuratorsRed, label: `Curators`, path: '/curators', },
        { icon: images.NavCampaigns, icon2: images.NavCampaignsRed, label: `Campaigns`, path: '/campaigns', },
        // { icon: images.NavWallet, icon2: images.NavWalletRed, label: `Wallet`, path: '/wallet', },
    ];

    const current = '/' + (location.pathname.split('/')[1] || 'curators');

    return (
        <React.Fragment>
            {isLoggedIn ? (
                <div className="h-screen w-screen xl:grid xl:grid-cols-12">
                    <div id="Navigation" className="col-span-2 hidden xl:flex flex-col justify-between border-r px-6 py-6 h-screen">
                        <Navigation user={user} items={items} current={current} setDrawer={setDrawer} />
                    </div>

                    {/* mobile */}
                    <div className="flex xl:hidden justify-between p-[24px]">
                        <a href="/" className="inline-block">
                            <img src={images.LogoDark} alt="UR-PR logo" className="w-[27px]" />
                        </a>
                        <div className="bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transi h-[30px] w-[30px] flex justify-center items-center" onClick={() => setDrawer(true)}>
                            <MenuIcon color="disabled" />
                        </div>
                    </div>
                    <Drawer
                        open={drawer}
                        footer={null}
                        placement="right"
                        closable={true}
                        onClose={close}
                    >
                        <div className="flex flex-col justify-between h-full">
                            <Navigation user={user} items={items} current={current} setDrawer={setDrawer} />
                        </div>
                    </Drawer>

                    <div className="xl:col-span-10 xl:ml-32 xl:mt-14 xl:mr-14 p-[24px] borders overflow-scroll no-scrollbar">{children}</div>
                </div>
            ) : (
                <div>{children}</div>
            )}
        </React.Fragment>
    );
}

export default Leyout;

const Navigation = ({ items, current, user, setDrawer }: {
    items: { icon: string; icon2: string; path: string; label: string; }[];
    current: string;
    user: types.User;
    setDrawer: (drawer: boolean) => void;
}) => {

    const [buy, setBuy] = React.useState(false);

    return (
        <React.Fragment>
            <div>
                <a href="./" className="xl:inline-block hidden">
                    <img src={images.LogoDark} alt="UR-PR logo" className="w-[42px] mb-16" />
                </a>
                <div className="flex xl:hidden justify-between items-center mb-8 text-white">
                    <img src={images.LogoDark} alt="UR-PR logo" className="w-[27px]" />
                    <div
                        className="bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transi h-[30px] w-[30px] flex justify-center items-center"
                        onClick={() => setDrawer(false)}
                    >
                        <CloseIcon color="disabled" />
                    </div>
                </div>

                {items.map(item => (
                    <Link key={item.path} to={`${item.path}`} className={`${current === item.path ? 'primary bg-[#FC003E14]' : ''} transi font-semibold text-base flex gap-3 p-3 rounded-xl mb-2 hover:bg-[#FC003E14]`}>
                        <img src={current === item.path ? item.icon2 : item.icon} alt={item.label} className="h-[20px]" /> {item.label}
                    </Link>
                ))}
            </div>

            <div>
                <div className="rounded-xl mb-8 bg-wallet px-6 py-8">
                    <div className="flex justify-between items-center">
                        <div className="text-gray-100 text-sm">Your Balance</div>
                        <div className="text-white text-3xls cursor-pointer px-2 py-1 border rounded-lg" onClick={() => setBuy(true)}>+ Add Fund</div>
                    </div>
                    <div className="text-[40px] text-white font-medium mt-3">{helpers.format.currency(user.wallet?.amount)}</div>
                </div>

                <div className="border-2 rounded-xl">
                    <div className="flex items-center gap-4 px-5 py-5 border-b">
                        <Avatar src={user.avatar_link} alt={user.name} className="w-[40px] h-[40px]" />
                        <div>
                            <div className="font-medium">{user.name}</div>
                            <div className="text-sm text-gray-500">{user.email}</div>
                        </div>
                    </div>
                    <div className="p-5 text-sms text-gray-600">
                        <Link to="/profile" className="block transi hover:text-black">Profile</Link>
                        <div className="mt-3 cursor-pointer transi hover:text-black" onClick={() => axius.signOutSuccess()}>Logout</div>
                    </div>
                </div>
            </div>

            <WalletForm open={buy} onClose={() => setBuy(false)} />
        </React.Fragment>
    );
}