import React from 'react';
import { CampaignsDetails, CampaignsForm, Loading, Pagination, Table } from 'components';
import { axius, datatables, types } from 'helpers';
import { Button } from 'antd';


const CampaignsList = () => {

    const limit = 9;

    const [row, setRow] = React.useState<types.Campaign | null>(null);
    const [data, setData] = React.useState<types.Campaign[]>([]);
    const [meta, setMeta] = React.useState({ next: '', previous: '', page: 1, total: 0 });
    const [start, setStart] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    const getData = (cursor = `0-${limit}`) => {
        setLoading(true);
        axius.get('campaigns', { cursor }).then(res => {
            if (res.status === 200) {
                setMeta(res.data.meta);
                setData(res.data.data);
            }
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                {loading && <Loading full={true} />}

                <div className="flex justify-between items-center mb-12">
                    <div className="text-3xl font-semibold">Campaigns</div>
                    <div>
                        <Button type="primary" size="large" onClick={() => setStart(true)}>
                            &nbsp; &nbsp; Start Campaign &nbsp; &nbsp;
                        </Button>
                    </div>
                </div>

                <div className="">
                    <Table
                        data={datatables.Campaigns.data(data)}
                        empty={datatables.Campaigns.empty}
                        loading={loading}
                        heading={datatables.Campaigns.heading}
                        onClick={(e) => setRow(data.find(row => row.id === e.id) as types.Campaign)}
                    />

                    <div className="mt-12">
                        <Pagination meta={meta} navigate={getData} />
                    </div>
                </div>
            </React.Suspense>

            <CampaignsForm open={start} onClose={() => setStart(false)} />
            <CampaignsDetails open={!!row?.id} item={row} onClose={() => setRow(null)}  />
        </React.Fragment>
    );
}

export default CampaignsList;