import React from 'react';
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';


interface Props {
    step: number;
    steps: string[];
    setStep: any;
}
const Steps = (props: Props) => {

    const { step, steps } = props;

    return (
        <React.Fragment>
            <div id="Steps" className="flex gap-6">
                {steps.map((stepLabel, i) => {
                    const ii = i + 1;
                    const isActive = step === ii || step > ii;
                    return (
                        <React.Fragment key={i}>
                            <div className={`flex items-center gap-2 ${isActive ? 'text-black' : 'text-gray-500'}`}>
                                <div className={`w-[20px] h-[20px] flex justify-center items-center rounded-full text-xs ${isActive ? 'bg-[#fc003f] text-white' : 'border'}`}>{i + 1}</div>
                                {stepLabel}
                            </div>
                            {steps.length > ii && (<NavigateNextIcon color="disabled" />)}
                        </React.Fragment>
                    )
                })}
            </div>
        </React.Fragment>
    );
}

export default Steps;