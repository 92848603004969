import { CuratorsAvatars, Status } from 'components';
import { helpers, images, types } from 'helpers';
// import { Error as ErrorIcon } from '@mui/icons-material';


export const Users = {
    heading: ['Name', 'Email address', 'Role', 'Date'],
    data: (data: types.User[]) => data.map(row => {
        return {
            id: row.id,
            name: row.name,
            email: row.email,
            role: row.role?.name || 'Odogwu',
            date: helpers.format.date(row.created_at),
        };
    })
};

export const Roles = {
    heading: ['Name', 'Role count', 'Date'],
    data: (data: types.Role[]) => data.map(row => {
        return {
            id: row.id,
            name: row.name,
            count: helpers.format.number(row.permissions.length, 0),
            date: helpers.format.date(row.created_at),
        };
    })
};

export const Curators = {
    heading: ['Logo', 'Name', 'Categories', 'Prices', 'Date'],
    data: (data: types.Curator[]) => data.map(row => {
        return {
            id: row.id,
            logo: <img src={row.logo_link} alt={row.name} className="h-[40px] rounded-md border" />,
            name: row.name,
            categories: helpers.ucWords(row.categories.join(', ')),
            prices: (
                <div>
                    {row.prices.map(item => (
                        <div key={item.platform}>
                            {types.SocialPlatformsLabels[item.platform as keyof typeof types.SocialPlatforms]}: {helpers.format.currency(item.price)}
                        </div>
                    ))}
                </div>
            ),
            date: helpers.format.date(row.created_at),
        };
    })
};

export const Campaigns = {
    heading: ['Name', 'Amount', 'Curators', 'Status', 'Date'],
    data: (data: types.Campaign[]) => data.map(row => {
        return {
            id: row.id,
            name: row.name,
            amount: helpers.format.currency(+row.price + +row.fee),
            curators: <CuratorsAvatars size={40} items={row.curators.map(c => c.logo_link)} />,
            status: (
                <div>
                    <Status status={row.status} />
                    {row.status === types.CampaignStatus.APPROVED && (
                        <div className="text-xs text-gray-500 mt-1 flex items-center">
                            {/* <ErrorIcon fontSize="small" />&nbsp; */}
                            Pending payment
                        </div>
                    )}
                </div>
            ),
            date: helpers.format.date(row.created_at),
        };
    }),
    empty: (
        <div>
            <img src={images.EmptyTable} alt="EmptyTable" className="h-[51px] mb-12" />
            <div className="font-semibold text-lg">You don't have any campaigns yet</div>
            <div className="text-gray-500s font-light mt-3s">To get started you need to start a campaign.</div>
        </div>
    ),
};