import React from 'react';
import { Button, Form, Modal, notification } from 'antd';
import { axius, helpers, images, types } from 'helpers';
import { Close as CloseIcon, NavigateBefore as NavigateBackIcon } from '@mui/icons-material';
import { CuratorsSocials, Steps } from 'components';
import { useNavigate } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import * as _ from 'lodash';


const CampaignsFormDetails = React.lazy(() => import('./CampaignsForm.Details'));
const CampaignsFormSocials = React.lazy(() => import('./CampaignsForm.Socials'));
const CampaignsFormSummary = React.lazy(() => import('./CampaignsForm.Summary'));
const CampaignsFormCurators = React.lazy(() => import('./CampaignsForm.Curators'));

interface Props {
    open: boolean;
    curator?: types.Curator;

    onClose: () => void;
}
const CampaignsForm = (props: Props) => {

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { open, curator, onClose } = props;

    const [step, setStep] = React.useState(1);
    const [links, setLinks] = React.useState({ press: '', bio: '' });
    const [socials, setSocials] = React.useState<types.CuratorForm[]>([]);
    const [curators, setCurators] = React.useState<types.Curator[]>([]);
    const [curatorx, setCuratorx] = React.useState<types.Curator[]>([]);
    const [curatorxs, setCuratorxs] = React.useState<types.Curator[]>([]);
    const [objective, setObjective] = React.useState<string[]>([]);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            if (curator) {
                // setStep(2);
                setCurators([curator]);
                setCuratorx([curator]);
            } else {
                axius.get('curators', { status: 'ACTIVE', cursor: '0-50' }).then(res => {
                    res.status === 200 && setCuratorx(res.data.data);
                    res.status === 200 && setCuratorxs(res.data.data);
                });
            }
        }
    }, [open]);

    React.useEffect(() => {
        const copyCuratorx = helpers.copyObject(curatorxs) as types.Curator[];
        const hasBlog = (socials: string[]) => socials.some(r => ['blog'].includes(r));
        const notBlog = (socials: string[]) => socials.some(r => Object.keys(types.SocialPlatforms).filter(r => r !== 'blog').includes(r));

        setCuratorx(
            copyCuratorx.filter(cc => (objective.includes('blog') && hasBlog(cc.socials.map(s => s.platform))) || (objective.includes('th') && notBlog(cc.socials.map(s => s.platform))))
        )

        if (helpers.isLocal && step === 1) {
            form.setFieldsValue({
                name: helpers.ucFirst(faker.lorem.words()),
                links: {
                    assets: 'https://docs.googlesheets.com/share/' + faker.lorem.words().split(' ').join('-'),
                    profile: faker.lorem.words().split(' ').join('-') + '.com',
                    instagram: faker.person.middleName(),
                    artist_name: faker.person.zodiacSign(),
                    caption: faker.lorem.words(),
                }
            });
        }
    }, [objective]);

    const close = () => {
        setStep(1);
        setLinks({ bio: '', press: '' });
        setSocials([]);
        setCurators([]);
        setObjective([]);
        form.resetFields();
        setSubmitting(false);
        onClose();
    }

    const submit = async () => {
        if (step === 5) {
            setSubmitting(true);
            const v = await form.validateFields();
            const vv = {
                ...v,
                links: { ...v.links, ...links },
                curators: socials,
            };
            // console.log(vv); return;
            axius.post(`campaigns`, vv).then(res => {
                if (res.status === 201) {
                    close();
                    notification.success({ message: `Campaign Successfully Submitted`, description: `You've successfully submitted your campaign` });
                    navigate(`/campaigns`);
                } else {
                    notification.error({ ...res });
                }
                setSubmitting(false);
            });
        } else {
            if ((step === 3 && curators.length > 0) || (step === 3 && socials.length > 0) || step !== 3) {
                setStep(st => (st + 1));
            } else {
                notification.warning({ message: `Select your preferred ${step === 3 ? 'curators' : 'socials'}` });
            }
        }
    }

    return (
        <React.Fragment>
            <Modal open={open} closable={false} maskClosable={false} footer={null} className="CampaignsForm">
                <div className="flex justify-center pb-12">
                    <div className="w-full xl:w-10/12">
                        <div className="flex justify-between items-center mb-12 xl:mb-20">
                            <img src={images.LogoDark} alt="UR-PR" className="w-[30px]" />
                            <div className="hidden xl:block">
                                <Steps step={step} setStep={setStep} steps={['Objective', 'Provide Details', 'Select Curator(s)', 'Select Plaforms', 'Review and Submit']} />
                            </div>
                            <div className="bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transi h-[30px] w-[30px] flex justify-center items-center" onClick={close}>
                                <CloseIcon color="disabled" />
                            </div>
                        </div>

                        <div className="w-full xl:w-4/12 my-0 mx-auto">
                            <Form
                                form={form}
                                layout="vertical"
                                className="mt-8"
                                onFinish={submit}
                                autoComplete="off"
                            >
                                <div className="min-h-[75vh]">
                                    {/* ::: 1 ::: Objective */}
                                    <div style={{ display: step !== 1 ? 'none' : '' }} className="w-full xl:w-10/12 my-0 mx-auto">
                                        <div className="text-2xl font-semibold mb-2 text-center">What is the purpose of this campaign?</div>
                                        <div className="text-center primary text-lg font-medium mb-6">(You can select one or both)</div>
                                        <CuratorsSocials clickable={true} style="full" size="large" onChange={setObjective}
                                            items={[
                                                { platform: types.SocialPlatforms.blog, handle: 'Publish Article', count: 0 },
                                                { platform: types.SocialPlatforms.th, handle: 'Social Media', count: 0 },
                                            ]}
                                        />
                                        <p>&nbsp;</p>
                                        {objective.length > 0 && (
                                            <Button type="primary" block size="large" htmlType="button" onClick={submit}>Continue</Button>
                                        )}
                                    </div>

                                    {/* ::: 2 ::: Details */}
                                    <div style={{ display: step !== 2 ? 'none' : '' }} className="w-full xl:w-10/12 my-0 mx-auto">
                                        <CampaignsFormDetails submitting={submitting} objective={objective} setLinks={setLinks} submit={submit} />
                                    </div>

                                    {/* ::: 3 ::: Curator(s) */}
                                    <div style={{ display: step !== 3 ? 'none' : '' }}>
                                        <CampaignsFormCurators curators={curators} curatorx={curatorx} objective={objective} setCurators={setCurators} submit={submit} />
                                    </div>

                                    {/* ::: 4 ::: Social(s) */}
                                    <div style={{ display: step !== 4 ? 'none' : '' }}>
                                        <CampaignsFormSocials socials={socials} curators={curators} objective={objective} submit={submit} setSocials={setSocials} />
                                    </div>

                                    {/* ::: 5 ::: Summary */}
                                    <div style={{ display: step !== 5 ? 'none' : '' }} className="w-full xl:w-10/12 my-0 mx-auto">
                                        <CampaignsFormSummary form={form} links={links} socials={socials} curators={curators} submitting={submitting} submit={submit} />
                                    </div>
                                </div>

                                <div className="mt-12 flex justify-center">
                                    <div className="cursor-pointer" onClick={() => setStep(st => (st - 1))}><NavigateBackIcon /> Go Back</div>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default CampaignsForm;