import React from 'react';
import { Input, Popover } from 'antd';
import { images, types } from 'helpers';

interface Props {
    onChange?: (e: { input: string; socials: string[]; }) => void;
}
const Search = ({ onChange }: Props) => {

    const [search, setSearch] = React.useState<{ input: string; socials: string[]; }>({ input: '', socials: [] });

    React.useEffect(() => {
        onChange && onChange(search);
    }, [search]);

    const icons = {
        [types.SocialPlatforms.fb]: images.SocialFacebook,
        [types.SocialPlatforms.th]: images.SocialThreads,
        [types.SocialPlatforms.tt]: images.SocialTiktok,
        [types.SocialPlatforms.twt]: images.SocialTwitter,
        [types.SocialPlatforms.blog]: images.SocialGlobe,
        [types.SocialPlatforms.ig_reel]: images.SocialInstagram,
        [types.SocialPlatforms.ig_carousel]: images.SocialInstagram,
    };

    return (
        <React.Fragment>
            <div id="Search" className={`grid grid-cols-12 gap-4 justify-between`}>
                <div className="col-span-9">
                    <Input placeholder="Search" onChange={e => setSearch(ss => ({ ...ss, input: e.target.value.toLowerCase() }))} />
                </div>
                <>
                    <Popover
                        content={(
                            <div className="w-[300px] rounded-[8px]">
                                {(Object.keys(types.SocialPlatforms) as types.SocialPlatforms[]).map(social => (
                                    <div key={social} className={`
                                        rounded-full border-[1.5px] px-3 py-2 text-sm text-gray-500 flex gap-2 items-center float-left mr-2 mb-2 transi 
                                        ${search.socials.includes(social) ? 'border-[#fc003f] text-[#fc003f] bg-[#FFF0F3]' : ''} cursor-pointer hover:border-[#fc003f] hover:text-[#fc003f] hover:bg-[#FFF0F3]
                                    `}
                                        onClick={() => search.socials.includes(social) ? setSearch((ss) => ({ ...ss, socials: ss.socials.filter(s => s !== social) })) : setSearch((ss) => ({ ...ss, socials: [...ss.socials, social] }))}
                                    >
                                        {icons[social] && (<img src={icons[social]} className="h-[15px]" />)}
                                        {types.SocialPlatformsLabels[social]}
                                    </div>
                                ))}
                                <div className="clear-both" />
                            </div>
                        )}
                        trigger="click" placement="bottom"
                    >
                        <div className="col-span-3 border border-[#d9d9d9] rounded-[12px] flex justify-center items-center cursor-pointer hover:border-[#ff2957] transi">
                            <img src={images.Filter} alt="Filter" /> &nbsp;Filter
                        </div>
                    </Popover>
                </>

            </div>
        </React.Fragment>
    );

};

export default Search;