import React from 'react';
import { Button } from 'antd';
import _Store from '_Store';

interface Props {
    meta: {
        next: string;
        page: number;
        total: number;
        previous: string;
    };
    navigate: (cursor: string) => void;
}
const Pagination = ({ meta, navigate }: Props) => {

    const { _store: { _utils: { limit } } } = React.useContext(_Store);

    return (
        <React.Fragment>
            <div id="Pagination">
                <div className="flex justify-between items-center">
                    <Button disabled={!meta.previous} onClick={() => navigate(meta.previous)}>
                        Previous
                    </Button>
                    <div className="text-gray-500 text-sm">
                        {(meta.previous || meta.next) && (
                            <div>
                                Page {meta.page} of {Math.round(meta.total / limit) || 1}
                            </div>
                        )}
                    </div>
                    <Button disabled={!meta.next} onClick={() => navigate(meta.next)}>
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Pagination;