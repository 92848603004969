import React from 'react';
import { Avatar } from 'antd';


interface Props {
    size?: number;
    items: string[];
}
const CuratorsAvatars = ({ size, items }: Props) => {

    return (
        <React.Fragment>
            <div className="text-centers my-6s">
                <Avatar.Group>
                    {items.map(c => (
                        <Avatar key={c} src={c} className={`h-[${size || 76}px] w-[${size || 76}px]`} alt={c} />
                    ))}
                </Avatar.Group>
            </div>
        </React.Fragment>
    );
}

export default CuratorsAvatars;