import React from 'react';
import { Button } from 'antd';
import { images } from 'helpers';

const GoogleLogin = () => {

    return (
        <React.Fragment>
            <div id="GoogleLogin" className="mt-4">
                <Button size="large" block className="cursor-not-allowed">
                    <div className="flex gap-3 justify-center items-center">
                        <img src={images.SocialGoogle} alt="GoogleIcon" /> Sign up with Google
                    </div>
                </Button>
            </div>
        </React.Fragment>
    );
}

export default GoogleLogin;