import React from 'react';
import { Loading, } from 'components';
import { axius, countries, helpers, types } from 'helpers';
import { Button, Form, Input, notification } from 'antd';
import _Store from '_Store';
import { CameraAltOutlined as CameraAltOutlinedIcon } from '@mui/icons-material';


const Profile = () => {

    const [form] = Form.useForm();
    const { _store: { _auth: { user } }, _setUser } = React.useContext(_Store);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [file, setFile] = React.useState(null);
    const [image, setImage] = React.useState(null);
    const [upload, setUpload] = React.useState(false);
    const [avatar, setAvatar] = React.useState(null);
    const [submitting, setLoading] = React.useState(false);

    React.useEffect(() => {
        form.setFieldsValue(user);
    }, [user]);

    React.useEffect(() => {
        file && upload && uploadFile();
        // eslint-disable-next-line
    }, [file]);

    const changeFile = () => {
        const fale = (inputRef.current?.files || [])[0];
        if (fale) {
            const reader = new FileReader();
            reader.onload = () => {
                setFile(fale as any);
                setImage(reader.result as any);
                setUpload(true);
            }
            reader.readAsDataURL(fale);
        }
    }

    const uploadFile = () => {
        axius.postFile(`upload`, { file, folder: 'users' }).then(res => {
            if (res.status === 201) {
                setAvatar(res.data[0].name);
            }
            setUpload(false);
        });
    }

    const submit = (v: any) => {
        setLoading(true);
        axius.put(`users/${user.id}`, { ...v, avatar: avatar || user.avatar }).then(res => {
            if (res.status === 200) {
                _setUser(res.data);

                setFile(null);
                setImage(null);
                setAvatar(null);
                notification.success({ message: 'Profile details saved' });
            } else {
                notification.error({ ...res });
            }
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <div className="flex justify-between items-center mb-12">
                    <div className="text-3xl font-semibold">Profile</div>
                    <div></div>
                </div>

                <div className="w-4/12 my-0 mx-autos">
                    <Form
                        form={form}
                        layout="vertical"
                        className="mt-8"
                        onFinish={submit}
                        autoComplete="off"
                    >
                        <input
                            ref={inputRef}
                            type="file"
                            accept={`image/*`}
                            onChange={changeFile}
                            style={{ display: 'none' }}
                        />
                        <div className="flex justify-center">
                            <div
                                className="h-[104px] w-[104px] bg-img rounded-full"
                                style={{ backgroundImage: `url('${image || user.avatar_link}')`, }}
                            >
                                <div className="bg-bg h-[104px] w-[104px] rounded-full flex justify-center items-center">
                                    <CameraAltOutlinedIcon fontSize='large' style={{ color: '#fff' }} onClick={() => inputRef.current?.click()} className="cursor-pointer" />
                                </div>
                            </div>
                        </div>
                        <Form.Item name="name" label="Full name" rules={helpers.requiredInput}>
                            <Input size="large" type="text" disabled={submitting} />
                        </Form.Item>
                        <Form.Item name="email" label="E-mail" rules={helpers.requiredInput}>
                            <Input size="large" type="email" disabled={submitting} />
                        </Form.Item>

                        <Form.Item label="Category">
                            <Input size="large" disabled={true} value={types.UserTypesLabels[user.type]} />
                        </Form.Item>

                        <Form.Item label="Country">
                            <Input size="large" disabled={true} value={countries.find(c => c.code.toLowerCase() === user.country.toLowerCase())?.name || user.country} />
                        </Form.Item>

                        <Button type="primary" block size="large" htmlType="submit" className="mt-12">Save Profile</Button>
                    </Form>
                </div>
            </React.Suspense>
        </React.Fragment>
    );
}

export default Profile;