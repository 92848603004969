import React from 'react';
import { axius, helpers, images, types } from 'helpers';
import { Attachment as AttachmentIcon } from '@mui/icons-material';


interface Props {
    row?: { name: string; link: string; };
    folder: types.Folders;
    accept?: string;
    onSuccess?: (e: { name: string; link: string; }) => void;
}
const Upload = ({ accept, row, folder, onSuccess }: Props) => {

    const inputRef = React.useRef<HTMLInputElement>(null);

    const [name, setName] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [image, setImage] = React.useState(null);
    const [upload, setUpload] = React.useState(false);

    React.useEffect(() => {
        file && upload && uploadFile();
        // eslint-disable-next-line
    }, [file]);

    React.useEffect(() => {
        if (row?.name) {
            setName(row.name as any);
            setImage(row.link as any);
        }
    }, [row]);

    const changeFile = () => {
        const fale = (inputRef.current?.files || [])[0];
        if (fale) {
            const reader = new FileReader();
            reader.onload = () => {
                setFile(fale as any);
                setImage(reader.result as any);
                setUpload(true);
            }
            reader.readAsDataURL(fale);
        }
    }

    const uploadFile = () => {
        axius.postFile(`upload`, { file, folder }).then(res => {
            if (res.status === 201) {
                setName(res.data[0].name);
                onSuccess && onSuccess(res.data[0]);
            }
            setUpload(false);
        });
    }

    const deleteFile = () => {
        axius.delte(`upload/${folder}/${name}`).then(res => {
            if (res.status === 200) {
                setName(null);
                setFile(null);
                setImage(null);
                onSuccess && onSuccess({ name: '', link: '' });
                // inputRef.current?.value = null;
            }
        });
    }

    const isImage = (name || '').match(/\.(jpg|jpeg|png|gif)$/i);

    return (
        <React.Fragment>
            <div id="Upload" className="border rounded-xl">
                <input
                    ref={inputRef}
                    type="file"
                    accept={`${accept || 'image/*'}`}
                    onChange={changeFile}
                    style={{ display: 'none' }}
                />

                {image ? (
                    <div className="p-[12px] flex gap-4s justify-between items-center">
                        <div className="w-9/12 pr-4">
                            {isImage ? (
                                <img src={image} alt="imagex" className="rounded" onError={helpers.imgError} />
                            ) : (
                                <div className="truncate text-gray-500 text-sm"><AttachmentIcon /> {(file || { name: '' }).name}</div>
                            )}
                        </div>
                        <div className="w-3/12 text-right">
                            <div
                                className="p-2 rounded-xl border border-gray-800 w-full text-center text-sm cursor-pointer hover:bg-gray-100 transi mb-2"
                                onClick={() => inputRef.current?.click()}
                            >
                                Replace {isImage ? 'Image' : 'File'}
                            </div>
                            <div
                                className="p-2 rounded-xl border border-red-800 w-full text-center text-sm cursor-pointer hover:bg-red-100 text-red-600 transi"
                                onClick={deleteFile}
                            >
                                Delete {isImage ? 'Image' : 'File'}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        onClick={() => inputRef.current?.click()}
                        className="rounded-[8px] text-center cursor-pointer bg-slate-800s bg-darks hover:bg-gray-50 transi p-[12px]"
                    >
                        <img src={images.Upload} alt="Upload" className="inline-block mx-auto mb-2" />
                        <div className="mb-2 text-whites">
                            <span className="primarys font-medium">Click to upload</span>
                        </div>
                        <div className="text-xs text-gray-600 mb-2">{accept ? `Files supported: ${accept}` : 'SVG, PNG, JPG or GIF (max. 800x400px)'}</div>
                        {/* <div className="text-xs text-gray-400 mb-2">(max. 800x400px)</div> */}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default Upload;