import React from 'react';
import { Avatar, Button, Modal } from 'antd';
import { types } from 'helpers';
import { Close as CloseIcon } from '@mui/icons-material';
import { CampaignsForm, CuratorsSocials } from 'components';

interface Props {
    item: types.Curator;
    open: boolean;
    button?: boolean;

    onClose: () => void;
}
const CuratorsDetails = ({ item, open, button, onClose }: Props) => {

    const [start, setStart] = React.useState(false);

    const blogs = (item.socials || []).filter(social => social.platform === 'blog');
    const socials = (item.socials || []).filter(social => social.platform !== 'blog');

    return (
        <React.Fragment>
            <Modal open={open} closable={false} centered footer={null} width={720} onClose={onClose} onCancel={onClose}>
                <div className="flex justify-end mb-12">
                    <div className="bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transi h-[30px] w-[30px] flex justify-center items-center" onClick={onClose}>
                        <CloseIcon color="disabled" />
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <Avatar src={item.logo_link} alt={item.name} className="h-[76px] w-[76px]" />
                    {button && (
                        <Button type="primary" size="large" onClick={() => { onClose(); setStart(true); }}>
                            &nbsp; &nbsp; Start Campaign &nbsp; &nbsp;
                        </Button>
                    )}
                </div>
                <div className="text-xl font-semibold truncate">{item.name}</div>
                <div className="text-base text-gray-500">{item.description}</div>

                {blogs.length > 0 && (
                    <>
                        <div className="text-sm font-semibold my-4">Blog Supported</div>
                        <CuratorsSocials items={blogs} showKount="count" />
                    </>
                )}

                {socials.length > 0 && (
                    <>
                        <div className="text-sm font-semibold my-4">Socials Supported</div>
                        <CuratorsSocials items={socials} showKount="count" />
                    </>
                )}
            </Modal>

            <CampaignsForm open={start} curator={item} onClose={() => setStart(false)} />
        </React.Fragment>
    );
};

export default CuratorsDetails;