import React from 'react';

// ::: app pages
import Profile from 'pages/Profile';
import Curators from 'pages/Curators/CuratorsList';
import Campaigns from 'pages/Campaigns/CampaignsList';

// ::: auth pages
const Web = React.lazy(() => import('pages/Web/_Web'));
const Auth = React.lazy(() => import('pages/Auth/_Auth'));
const NoPage = React.lazy(() => import('pages/NoPage'));


const routes = [
    { path: `/`, element: Web, auth: false, },
    { path: `/privacy-policy`, element: Web, auth: false, },
    { path: `/terms-conditions`, element: Web, auth: false, },
    { path: `/auth/*`, element: Auth, auth: false, },

    { path: `*`, element: NoPage, auth: true, },

    { path: `/profile`, element: Profile, auth: true, },
    { path: `/curators`, element: Curators, auth: true, },
    { path: `/campaigns`, element: Campaigns, auth: true, },
];

export default routes;