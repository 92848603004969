import React from 'react';
import { CampaignsForm, CuratorsCard, Loading, LoadingMin, Search } from 'components';
import { axius, types } from 'helpers';
import { Button } from 'antd';


const CuratorsList = () => {

    const limit = 120;

    const [start, setStart] = React.useState(false);
    const [data, setData] = React.useState<types.Curator[]>([]);
    const [search, setSearch] = React.useState<{ input: string; socials: string[]; }>({ input: '', socials: [] });
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    const getData = (cursor = `0-${limit}`) => {
        setLoading(true);
        axius.get('curators', { cursor, status: 'ACTIVE' }).then(res => {
            res.status === 200 && setData(res.data.data);
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <div className="grid grid-cols-12 items-center mb-12">
                    <div className="col-span-6 xl:col-span-3 text-2xl xl:text-3xl font-semibold">Discover Curators</div>
                    <div className="col-span-6 xl:col-span-4 text-center">
                        <Button type="primary" size="large" onClick={() => setStart(true)}>
                            &nbsp; &nbsp; Start Campaign &nbsp; &nbsp;
                        </Button>
                    </div>
                    <div className="col-span-12 xl:col-span-5 mt-6 xl:m-0">
                        <Search onChange={setSearch} />
                    </div>
                </div>

                <div className="text-lg font-bold xl:mb-8 mb-6">Top Curators 🔥</div>

                {loading && <div className="flex justify-center items-center h-[40vh] w-full"><LoadingMin size={30} /></div>}
                <div className="grid grid-cols-12 gap-5">
                    {!loading && data.filter(c => (c.name.toLowerCase().includes(search.socials.length > 0 && !search.input ? '.:.:.' : search.input)) || c.socials.map(s => s.platform).filter(ss => search.socials.includes(ss)).length > 0).map(item => (
                        <CuratorsCard key={item.id} item={item} className="col-span-12 xl:col-span-3" />
                    ))}
                </div>

                <CampaignsForm open={start} onClose={() => setStart(false)} />
            </React.Suspense>
        </React.Fragment>
    );
}

export default CuratorsList;